<template>
  <v-row justify="center">
    <v-dialog
      v-if="resolutionScreen >= 500"
      v-model="shower"
      scrollable
      persistent
      max-width="1100px"
    >
      <v-card>
        <v-card-title>{{
          $t("renamedocument.renamedocumentheader")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-layout v-if="pagerename === 'getTemplatePage'" row wrap class="ma-6">
          <v-flex lg6 class="pl-4">
            <span style="color:red;">
              {{ $t("renamedocument.infodefault1") }} <br />
              {{ $t("renamedocument.infodefault2") }}
            </span>
          </v-flex>
          <v-flex lg6 class="text-end">
            <v-btn
              :color="color.theme"
              class="white--text"
              @click="getKeyRenameDocument(), (pagerename = 'editTemplatePage')"
            >
              {{ $t("renamedocument.selectformatrenamedocument") }}
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap class="ma-6">
          <v-flex lg1 md1 class="">
            <v-btn
              :color="color.theme"
              class="white--text"
              @click="
                getTemplateRenameDocument(),
                  (pagerename = 'getTemplatePage'),
                  (statuseditrenamedocument = false)
              "
              fab
              small
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex lg6 md6 class="text-center pt-3">
            {{ $t("renamedocument.doctype") }}
          </v-flex>
          <v-flex lg5 md5 class="text-center pt-3">
            {{ $t("renamedocument.format") }}
          </v-flex>
        </v-layout>
        <v-card-text v-if="loadprogress" style="height: 500px">
          <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
        <v-card-text
          v-else-if="pagerename === 'getTemplatePage'"
          style="height: 500px"
          :style="
            datatemplaterename.length === 0
              ? 'display: flex;justify-content: center; align-items: center;'
              : ''
          "
        >
          <v-card-title class="mt-n6" v-if="datatemplaterename.length === 0">{{
            $t("renamedocument.emptysetting")
          }}</v-card-title>
          <v-layout v-else row wrap fill-height class="mt-n4">
            <v-flex lg12>
              <div v-for="(item, index) in datatemplaterename" :key="index">
                <v-card v-if="item.status_default !== 'R'" class="mx-6 mb-4">
                  <v-card-text>
                    <v-layout fill-height row wrap>
                      <v-flex lg8 md8>
                        <v-card-title>
                          {{
                            item.document_type === "default"
                              ? $t("renamedocument.default")
                              : $t("default") === "th"
                              ? listDoctypeuse[
                                  listDoctypeuse.findIndex(
                                    (element) =>
                                      element.document_type_code ===
                                      item.document_type_code
                                  )
                                ].document_type_th
                              : listDoctypeuse[
                                  listDoctypeuse.findIndex(
                                    (element) =>
                                      element.document_type_code ===
                                      item.document_type_code
                                  )
                                ].document_type_eng
                          }}
                        </v-card-title>
                        <v-card-subtitle>
                          <br />
                          {{ $t("renamedocument.createdate") }} :
                          {{ fnFormatDatetime(item.cre_dtm) }}<br />
                          {{ $t("renamedocument.example") }} :
                          {{ item.example_name }}
                        </v-card-subtitle>
                      </v-flex>
                      <v-flex lg4 md4>
                        <v-card-text class="mt-n3">
                          <!-- <v-spacer></v-spacer> -->
                          <v-switch
                            :light="false"
                            inset
                            hide-details
                            class="ml-10 pl-12"
                            v-model="item.status_default_switch"
                            :label="
                              item.status_default_switch === false
                                ? $t('renamedocument.closerename')
                                : $t('renamedocument.openrename')
                            "
                            @change="setStatusDefault(item)"
                          >
                          </v-switch>
                        </v-card-text>
                        <v-card-actions class="mr-3">
                          <v-spacer></v-spacer>
                          <v-btn
                            fab
                            small
                            color="warning"
                            @click="
                              editTemplateRenameDocument(item),
                                (showeditrename = false)
                            "
                            @mouseover="
                              (showindex = index), (showeditrename = true)
                            "
                            @mouseleave="
                              (showindex = -1), (showeditrename = false)
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                            <v-badge
                              v-if="showindex === index"
                              style="z-index:10000;bottom:-45px;left:-50px;"
                              :value="showeditrename"
                              :content="$t('renamedocument.editrename')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            fab
                            small
                            color="error"
                            @click="
                              setItemDataTemplateRename(item),
                                (showdeleterename = false)
                            "
                            @mouseover="
                              (showindex = index), (showdeleterename = true)
                            "
                            @mouseleave="
                              (showindex = -1), (showdeleterename = false)
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                            <v-badge
                              v-if="showindex === index"
                              style="z-index:10000;bottom:-45px;left:-55px;"
                              :value="showdeleterename"
                              :content="$t('renamedocument.deleterename')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text v-else>
          <v-layout row wrap>
            <v-flex lg7>
              <v-select
                clearable
                outlined
                dense
                class="pa-1 pt-0 pb-0"
                v-model="documenttype"
                :items="listDoctypeuse"
                item-value="document_type_code"
                :item-text="
                  $t('default') === 'th'
                    ? 'document_type_th'
                    : 'document_type_eng'
                "
                prepend-icon="line_style"
                :color="color.theme"
                :item-color="color.theme"
                multiple
                item-disabled="disabled"
                @click:clear="(documenttype = []), (statuscheckdefault = false)"
                :disabled="statuseditrenamedocument"
              >
                <template v-slot:label>
                  <span style="line-height: 22px;">{{
                    $t("myinboxPage.conditionOptional.doctype")
                  }}</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="fnSelectAllDocType">
                    <v-list-item-action>
                      <v-icon
                        :color="statuscheckdefault === true ? color.theme : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="line-height: 26px;"
                        :style="
                          statuscheckdefault === true
                            ? 'color:' + color.theme
                            : ''
                        "
                      >
                        {{ $t("renamedocument.selectall") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      listDoctypeuse.length === documenttype.length &&
                        index === 0
                    "
                  >
                    {{ $t("renamedocument.selectall") }}
                  </span>
                  <span v-else-if="index < maxDisplay"
                    >{{
                      $t("default") === "th"
                        ? item.document_type_th
                        : item.document_type_eng
                    }}
                    ,
                  </span>
                  <span
                    v-if="
                      index === maxDisplay &&
                        listDoctypeuse.length !== documenttype.length
                    "
                    class="grey--text caption"
                    >(+{{ documenttype.length - maxDisplay }}
                    {{ $t("renamedocument.type") }})</span
                  >
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item
                    :disabled="item.disabled"
                    @click="item.disabled ? '' : fnSelectDocType(item)"
                  >
                    <v-list-item-action>
                      <v-icon
                        v-if="
                          documenttype.filter(
                            (ele) => ele === item.document_type_code
                          ).length > 0
                        "
                        :color="color.theme"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else>
                        mdi-checkbox-blank-outline
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="line-height: 26px;"
                        :style="item.disabled ? 'color: rgba(0,0,0,.38)' : ''"
                      >
                        {{
                          $t("default") === "th"
                            ? item.document_type_th
                            : item.document_type_eng
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-list
                dense
                class="mt-n3 ml-8 mr-1 rounded-lg"
                style="background-color: #9E9E9E;"
              >
                <v-list-item class="mb-n2">
                  <span class="white--text">{{
                    $t("renamedocument.example")
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="white--text" v-if="dataformat.length === 0">{{
                    $t("renamedocument.notselect")
                  }}</span>
                  <span class="white--text" v-else>{{
                    formatNameDocument
                  }}</span>
                </v-list-item>
              </v-list>
              <v-card-text style="height: 50%; overflow-y:scroll;" class="mt-6">
                <v-list dense class="my-n6">
                  <v-list-item
                    v-for="(item, i) in dataformat"
                    :key="i"
                    class="my-1"
                  >
                    <v-text-field
                      v-if="item.key !== 'textandsymbol'"
                      outlined
                      dense
                      :disabled="item.key !== 'textandsymbol'"
                      :label="$t(item.name)"
                      style="line-height:24px"
                    >
                    </v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.text"
                      outlined
                      dense
                      :label="$t(item.name)"
                      :error-messages="dataformat[i].errortext"
                      :rules="symbolErrors"
                      required
                      @input="$v.dataformat.$each[i].text.$touch()"
                      @blur="$v.dataformat.$each[i].text.$touch()"
                      style="line-height:24px"
                    >
                    </v-text-field>
                    <v-btn
                      fab
                      x-small
                      color="red"
                      dark
                      class="ml-3 mt-n6"
                      @click="deleteDataRename(i)"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-flex>
            <v-flex lg5>
              <v-list dense class="pl-6">
                <v-list-item class="mt-n3">
                  <v-list-item-subtitle style="line-height: 24px;">{{
                    $t("renamedocument.titleformat")
                  }}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  v-for="item in datakeyrename"
                  class="mt-3"
                  :key="item.key"
                >
                  <v-btn
                    fab
                    x-small
                    color="green"
                    class="mr-3"
                    @click="addDataRename(item)"
                    :disabled="checkKeyRename || dataformat.includes(item)"
                    elevation="3"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                  <span>{{ $t(item.name) }}</span>
                </v-list-item>
                <v-divider class="mt-6"></v-divider>
                <v-list-item class="mt-3">
                  <v-btn
                    fab
                    x-small
                    color="green"
                    class="mr-3"
                    @click="addDataRename('textandsymbol')"
                    :disabled="checkKeyTextSymbol"
                    elevation="3"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                  <span>{{ $t("renamedocument.textandsymbol") }}</span>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closeDialog()">{{
            $t("admin.close")
          }}</v-btn>
          <v-btn
            @click="setTemplateRenameDocument()"
            :disabled="
              (dataformat.length <= 0 ||
                documenttype.length <= 0 ||
                dataformat.length ===
                  dataformat.filter((item) => item.key === 'textandsymbol')
                    .length) &&
                statuscheckdefault === false
            "
            :color="color.theme"
            class="white--text"
            >{{ $t("myinboxPage.settingHeader.submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-else
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
    >
      <v-card>
        <v-card-text class="pa-5 mb-n12" style="overflow-y:hidden;">
          <v-layout class="pt-2 pb-4">
            <v-flex d-flex justify-center class="ml-6">
              <span :style="headerPage">{{
                $t("renamedocument.renamedocumentheader")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="closeDialog()"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-layout v-if="pagerename === 'getTemplatePage'" row wrap class="ma-6">
          <v-flex lg12 class="text-end">
            <v-btn
              :color="color.theme"
              class="white--text"
              @click="getKeyRenameDocument(), (pagerename = 'editTemplatePage')"
            >
              {{ $t("renamedocument.selectformatrenamedocument") }}
            </v-btn>
          </v-flex>
          <v-flex lg6 class="mt-2">
            <span style="color:red; font-size: 14px">
              {{ $t("renamedocument.infodefault1") }} <br />
              {{ $t("renamedocument.infodefault2") }}
            </span>
          </v-flex>
        </v-layout>
        <v-layout v-else row wrap class="ma-6">
          <v-flex xs2 class="">
            <v-btn
              :color="color.theme"
              class="white--text"
              @click="
                getTemplateRenameDocument(),
                  (pagerename = 'getTemplatePage'),
                  (statuseditrenamedocument = false)
              "
              fab
              small
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex :style="titleText" xs10 class="text-center pt-2 ml-n4">
            {{ $t("renamedocument.doctype") }}
          </v-flex>
        </v-layout>
        <v-card-text v-if="loadprogress" style="height: 500px">
          <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
            <v-progress-circular
              indeterminate
              size="64"
              :color="color.theme"
            ></v-progress-circular>
          </v-overlay>
        </v-card-text>
        <v-card-text
          v-else-if="pagerename === 'getTemplatePage'"
          style="height: 500px"
          :style="
            datatemplaterename.length === 0
              ? 'display: flex;justify-content: center; align-items: center;'
              : ''
          "
        >
          <v-card-title class="mt-n6" v-if="datatemplaterename.length === 0">{{
            $t("renamedocument.emptysetting")
          }}</v-card-title>
          <v-layout v-else row wrap fill-height class="mt-n4">
            <v-flex lg12>
              <div
                v-for="(item, index) in datatemplaterename"
                :key="item.document_type_code"
              >
                <v-card v-if="item.status_default !== 'R'" class="mx-2 mb-4">
                  <v-card-text>
                    <v-layout fill-height row wrap>
                      <v-flex lg8 md8>
                        <v-card-title :style="titleText">
                          {{
                            item.document_type === "default"
                              ? $t("renamedocument.default")
                              : $t("default") === "th"
                              ? listDoctypeuse[
                                  listDoctypeuse.findIndex(
                                    (element) =>
                                      element.document_type_code ===
                                      item.document_type_code
                                  )
                                ].document_type_th
                              : listDoctypeuse[
                                  listDoctypeuse.findIndex(
                                    (element) =>
                                      element.document_type_code ===
                                      item.document_type_code
                                  )
                                ].document_type_eng
                          }}
                        </v-card-title>
                        <v-card-subtitle :style="subTitleText">
                          {{ $t("renamedocument.createdate") }} :
                          {{ fnFormatDatetime(item.cre_dtm) }}<br />
                          {{ $t("renamedocument.example") }} :
                          {{ item.example_name }}
                        </v-card-subtitle>
                      </v-flex>
                      <v-flex xs12>
                        <v-card-actions>
                          <v-switch
                            :light="false"
                            inset
                            hide-details
                            class="pl-4"
                            v-model="item.status_default_switch"
                            :label="
                              item.status_default_switch === false
                                ? $t('renamedocument.closerename')
                                : $t('renamedocument.openrename')
                            "
                            @change="setStatusDefault(item)"
                          >
                          </v-switch>
                        </v-card-actions>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            fab
                            small
                            color="warning"
                            @click="
                              editTemplateRenameDocument(item),
                                (showeditrename = false)
                            "
                            @mouseover="
                              (showindex = index), (showeditrename = true)
                            "
                            @mouseleave="
                              (showindex = -1), (showeditrename = false)
                            "
                          >
                            <v-icon>mdi-pencil</v-icon>
                            <v-badge
                              v-if="showindex === index"
                              style="z-index:10000;bottom:-45px;left:-50px;"
                              :value="showeditrename"
                              :content="$t('renamedocument.editrename')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                          <v-btn
                            fab
                            small
                            color="error"
                            @click="
                              setItemDataTemplateRename(item),
                                (showdeleterename = false)
                            "
                            @mouseover="
                              (showindex = index), (showdeleterename = true)
                            "
                            @mouseleave="
                              (showindex = -1), (showdeleterename = false)
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                            <v-badge
                              v-if="showindex === index"
                              style="z-index:10000;bottom:-45px;left:-55px;"
                              :value="showdeleterename"
                              :content="$t('renamedocument.deleterename')"
                              transition="slide-y-transition"
                            ></v-badge>
                          </v-btn>
                        </v-card-actions>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text v-else>
          <v-layout row wrap>
            <v-flex xs12>
              <v-select
                clearable
                outlined
                dense
                class="pa-1 pt-0 pb-0"
                v-model="documenttype"
                :items="listDoctypeuse"
                item-value="document_type_code"
                :item-text="
                  $t('default') === 'th'
                    ? 'document_type_th'
                    : 'document_type_eng'
                "
                prepend-icon="line_style"
                :color="color.theme"
                :item-color="color.theme"
                multiple
                item-disabled="disabled"
                @click:clear="(documenttype = []), (statuscheckdefault = false)"
                :disabled="statuseditrenamedocument"
              >
                <template v-slot:label>
                  <span style="line-height: 22px;">{{
                    $t("myinboxPage.conditionOptional.doctype")
                  }}</span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="fnSelectAllDocType">
                    <v-list-item-action>
                      <v-icon
                        :color="statuscheckdefault === true ? color.theme : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("renamedocument.selectall") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <span
                    v-if="
                      listDoctypeuse.length === documenttype.length &&
                        index === 0
                    "
                  >
                    {{ $t("renamedocument.selectall") }}
                  </span>
                  <span v-else-if="index < maxDisplay"
                    >{{
                      $t("default") === "th"
                        ? item.document_type_th
                        : item.document_type_eng
                    }}
                    ,
                  </span>
                  <span
                    v-if="
                      index === maxDisplay &&
                        listDoctypeuse.length !== documenttype.length
                    "
                    class="grey--text caption"
                    >(+{{ documenttype.length - maxDisplay }}
                    {{ $t("renamedocument.type") }})</span
                  >
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item
                    :disabled="item.disabled"
                    @click="item.disabled ? '' : fnSelectDocType(item)"
                  >
                    <v-list-item-action>
                      <v-icon
                        v-if="
                          documenttype.filter(
                            (ele) => ele === item.document_type_code
                          ).length > 0
                        "
                        :color="color.theme"
                      >
                        mdi-checkbox-marked
                      </v-icon>
                      <v-icon v-else>
                        mdi-checkbox-blank-outline
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        style="line-height: 26px;"
                        :style="item.disabled ? 'color: rgba(0,0,0,.38)' : ''"
                      >
                        {{
                          $t("default") === "th"
                            ? item.document_type_th
                            : item.document_type_eng
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
              <v-list
                dense
                class="mt-n3 ml-8 mr-1 rounded-lg"
                style="background-color: #9E9E9E;"
              >
                <v-list-item class="mb-n2">
                  <span class="white--text">{{
                    $t("renamedocument.example")
                  }}</span>
                </v-list-item>
                <v-list-item>
                  <span class="white--text" v-if="dataformat.length === 0">{{
                    $t("renamedocument.notselect")
                  }}</span>
                  <span class="white--text" v-else>{{
                    formatNameDocument
                  }}</span>
                </v-list-item>
              </v-list>
              <v-card-text style="height: 50%; overflow-y:scroll;" class="mt-6">
                <v-list dense class="my-n6">
                  <v-list-item
                    v-for="(item, i) in dataformat"
                    :key="i"
                    class="my-1"
                  >
                    <v-text-field
                      v-if="item.key !== 'textandsymbol'"
                      outlined
                      dense
                      :disabled="item.key !== 'textandsymbol'"
                      :label="$t(item.name)"
                    >
                    </v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.text"
                      outlined
                      dense
                      :label="$t(item.name)"
                      :error-messages="dataformat[i].errortext"
                      :rules="symbolErrors"
                      required
                      @input="$v.dataformat.$each[i].text.$touch()"
                      @blur="$v.dataformat.$each[i].text.$touch()"
                    >
                    </v-text-field>
                    <v-btn
                      fab
                      x-small
                      color="red"
                      dark
                      class="ml-3 mt-n6"
                      @click="deleteDataRename(i)"
                    >
                      <v-icon small>mdi-minus</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-flex>
            <v-flex xs12 :style="titleText" class="text-center pt-3">
              {{ $t("renamedocument.format") }}
            </v-flex>
            <v-flex xs12>
              <v-list dense class="pl-6">
                <v-list-item class=" ml-n6">
                  <span style="line-height: 24px;">{{
                    $t("renamedocument.titleformat")
                  }}</span>
                </v-list-item>
                <v-list-item
                  v-for="item in datakeyrename"
                  class="mt-2"
                  :key="item.key"
                >
                  <v-btn
                    fab
                    x-small
                    color="green"
                    class="mr-3"
                    @click="addDataRename(item)"
                    :disabled="checkKeyRename || dataformat.includes(item)"
                    elevation="3"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                  <span>{{ $t(item.name) }}</span>
                </v-list-item>
                <v-divider class="mt-3"></v-divider>
                <v-list-item class="mt-2">
                  <v-btn
                    fab
                    x-small
                    color="green"
                    class="mr-3"
                    @click="addDataRename('textandsymbol')"
                    :disabled="checkKeyTextSymbol"
                    elevation="3"
                  >
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                  <span>{{ $t("renamedocument.textandsymbol") }}</span>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="closeDialog()"
            >{{ $t("admin.close") }}</v-btn
          >
          <v-btn
            height="40px"
            width="40%"
            class="elevation-0 white--text"
            :style="btnAction"
            :color="color.theme"
            :disabled="
              (dataformat.length <= 0 ||
                documenttype.length <= 0 ||
                dataformat.length ===
                  dataformat.filter((item) => item.key === 'textandsymbol')
                    .length) &&
                statuscheckdefault === false
            "
            @click="setTemplateRenameDocument()"
            >{{ $t("myinboxPage.settingHeader.submit") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <confirmdeleterenamedocument
      :show="openconfirmdeletetemplaterename"
      :data="itemdatatemplaterename"
      @deletetemplate="deleteTemplateRenameDocument"
      @close="
        (openconfirmdeletetemplaterename = false), (itemdatatemplaterename = {})
      "
    ></confirmdeleterenamedocument>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import formatDatetime from "../../globalFunctions/formatDatetime";
import checkCharacter from "@/globalFunctions/checkCharacter";

const confirmdeleterenamedocument = () =>
  import("@/components/optional/dialog-confirmdeleterenamedocument.vue");

const SpecialCharacterForFoldernameValidator = helpers.regex(
  "SpecialCharacterForFoldername",
  checkCharacter.checkSpecialCharacterForFoldername()
);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  mixins: [validationMixin],
  validations: {
    dataformat: {
      $each: {
        text: { required, SpecialCharacterForFoldernameValidator },
      },
    },
  },
  data() {
    return {
      loadprogress: true,
      documenttype: [],
      itemdatatemplaterename: {},
      datatemplaterename: [],
      datakeyrename: [],
      dataformat: [],
      pagerename: "getTemplatePage",
      maxDisplay: 1,
      openconfirmdeletetemplaterename: false,
      statuseditrenamedocument: false,
      showdeleterename: false,
      showeditrename: false,
      showindex: -1,
      listDoctypeuse: [],
      statuscheckdefault: false,
    };
  },
  props: ["show", "statusinboxoutbox"],
  components: {
    confirmdeleterenamedocument,
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.getTemplateRenameDocument();
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.selected = [];
        }
      },
    },
    Alldoctype() {
      console.log("effe");
      if (this.statuscheckdefault === true) {
        this.listDoctypeuse.map((item) => (item.disabled = true));
      } else {
        this.listDoctypeuse.map((item) => (item.disabled = false));
        for (let i = 0; i < this.datatemplaterename.length; i++) {
          if (this.datatemplaterename[i].status_default === "Y") {
            let indexdisable = this.listDoctypeuse.findIndex(
              (element) =>
                element.document_type_code ===
                this.datatemplaterename[i].document_type_code
            );
            if (indexdisable != -1) {
              this.listDoctypeuse[indexdisable].disabled = true;
            } else {
              continue;
            }
          }
        }
      }
      return this.statuscheckdefault;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    icon() {
      if (this.statuscheckdefault) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    formatNameDocument() {
      let formatNameDocument = "";
      for (let i = 0; i < this.dataformat.length; i++) {
        if (this.dataformat[i].key === "textandsymbol") {
          formatNameDocument += " " + this.dataformat[i].text;
        } else {
          formatNameDocument += " " + this.$t(this.dataformat[i].name);
        }
      }
      return formatNameDocument;
    },
    checkKeyRename() {
      let notextsymbol = this.dataformat.filter(
        (item) => item.key !== "textandsymbol"
      );
      if (notextsymbol.length >= 3) {
        return true;
      } else {
        return false;
      }
    },
    checkKeyTextSymbol() {
      if (this.dataformat.length > 0) {
        if (
          this.dataformat[this.dataformat.length - 1].key === "textandsymbol"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    symbolErrors() {
      if (this.dataformat.length > 0) {
        for (let i = 0; i < this.dataformat.length; i++) {
          if (!this.$v.dataformat.$each[i].text.$dirty) {
          } else if (!this.$v.dataformat.$each[i].text.required) {
            this.dataformat[i].errortext = this.$t(
              "renamedocument.addfilename"
            );
          } else if (
            !this.$v.dataformat.$each[i].text
              .SpecialCharacterForFoldernameValidator
          ) {
            this.dataformat[i].errortext = this.$t(
              "renamedocument.nospecialcharacter"
            );
          } else {
            this.dataformat[i].errortext = "";
          }
        }
      }
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 18px; line-height: 28px;";
    },
    subTitleText() {
      return "font-size: 15px; line-height: 28px;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
  },
  watch: {
    statuscheckdefault(val) {
      if (val === true) {
        // this.listDoctypeuse.map((item) => (item.disabled = true));
        this.documenttype = [];
      } else {
        this.listDoctypeuse.map((item) => (item.disabled = false));
        for (let i = 0; i < this.datatemplaterename.length; i++) {
          if (this.datatemplaterename[i].status_default === "Y") {
            let indexdisable = this.listDoctypeuse.findIndex(
              (element) =>
                element.document_type_code ===
                this.datatemplaterename[i].document_type_code
            );
            if (indexdisable != -1) {
              this.listDoctypeuse[indexdisable].disabled = true;
            } else {
              continue;
            }
          }
        }
      }
    },
  },
  methods: {
    async getDataDocType() {
      let payload = {
        receiver_id: this.dataAccountId,
        receiver_type: "2",
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_all_doctype",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          if (res.data.status === "OK") {
            this.listDoctypeuse = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeDialog() {
      this.$emit("closedialog");
      setTimeout(() => {
        this.pagerename = "getTemplatePage";
      }, 1000);
    },
    fnFormatDatetime(rawdatetime) {
      let datetime = formatDatetime.formatdatetime(rawdatetime);
      return datetime;
    },
    setItemDataTemplateRename(item) {
      this.itemdatatemplaterename = {};
      this.itemdatatemplaterename = item;
      this.openconfirmdeletetemplaterename = true;
    },
    setStatusDefault(item) {
      item.status_default = item.status_default === "Y" ? "N" : "Y";
      this.updateStatusTemplate(item);
    },
    deleteTemplateRenameDocument(item) {
      item.status_default = "R";
      this.openconfirmdeletetemplaterename = false;
      this.updateStatusTemplate(item);
    },
    async updateStatusTemplate(item) {
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        template_id: item.template_id,
        status_default: item.status_default,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/update_status_template",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title:
                item.status_default === "Y"
                  ? this.$t("renamedocument.activatesuccess")
                  : item.status_default === "R"
                  ? this.$t("renamedocument.deletesuccess")
                  : this.$t("renamedocument.disablesuccess"),
            });
            this.getTemplateRenameDocument();
            this.itemdatatemplaterename = {};
          } else if (response.data.errorCode === "ER302") {
            Toast.fire({
              icon: "error",
              title: this.$t("renamedocument.cannotalreadyenabled"),
            });
            this.getTemplateRenameDocument();
            this.itemdatatemplaterename = {};
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("renamedocument.cannotdisableactivate"),
            });
            this.getTemplateRenameDocument();
            this.itemdatatemplaterename = {};
          }
        })
        .catch((error) => {
          console.log("error", error);
          Toast.fire({
            icon: "error",
            title: this.$t("renamedocument.cannotdisableactivate"),
          });
          this.itemdatatemplaterename = {};
        });
    },
    addDataRename(item) {
      let data;
      if (item === "textandsymbol") {
        data = {
          key: "textandsymbol",
          name: "renamedocument.textandsymbol",
          text: "",
          errortext: "",
        };
      } else {
        data = item;
      }
      this.dataformat.push(data);
    },
    deleteDataRename(index) {
      this.dataformat.splice(index, 1);
    },
    fnSelectAllDocType() {
      this.statuscheckdefault = !this.statuscheckdefault;
      let listdocument = [];
      this.$nextTick(() => {
        if (this.statuscheckdefault === false) {
          this.documenttype = [];
        } else {
          for (let i = 0; i < this.listDoctypeuse.slice().length; i++) {
            listdocument.push(this.listDoctypeuse[i].document_type_code);
          }
          this.documenttype = listdocument;
        }
      });
    },
    fnSelectDocType(item) {
      if (this.documenttype.length === this.listDoctypeuse.length) {
        this.statuscheckdefault = false;
        this.documenttype = [];
        this.documenttype.push(item.document_type_code);
      } else {
        let index = this.documenttype.indexOf(item.document_type_code);
        if (index !== -1) {
          this.documenttype.splice(index, 1);
        } else {
          this.documenttype.push(item.document_type_code);
        }
      }
    },
    async getTemplateRenameDocument() {
      this.loadprogress = true;
      this.statuscheckdefault = false;
      this.datatemplaterename = [];
      this.dataformat = [];
      this.documenttype = [];
      this.listDoctypeuse.map((item) => (item.disabled = false));
      var payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        inbox_status: this.statusinboxoutbox,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_template_rename_document",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            this.datatemplaterename = response.data.detail;
            this.datatemplaterename.map((item) =>
              item.status_default === "Y"
                ? (item.status_default_switch = true)
                : (item.status_default_switch = false)
            );
            let datadefault;
            for (let i = 0; i < this.datatemplaterename.length; i++) {
              if (this.datatemplaterename[i].document_type_code === "default") {
                datadefault = this.datatemplaterename.filter(
                  (item) => item.document_type_code !== "default"
                );
                datadefault.unshift(this.datatemplaterename[i]);
              }
              if (i + 1 === this.datatemplaterename.length && datadefault) {
                this.datatemplaterename = [...datadefault];
              }

              if (this.datatemplaterename[i].status_default === "Y") {
                let indexdisable = this.listDoctypeuse.findIndex(
                  (element) =>
                    element.document_type_code ===
                    this.datatemplaterename[i].document_type_code
                );
                if (indexdisable != -1) {
                  this.listDoctypeuse[indexdisable].disabled = true;
                } else {
                  continue;
                }
              }
            }
            this.loadprogress = false;
          } else {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async getKeyRenameDocument(item) {
      this.loadprogress = true;
      this.datakeyrename = [];
      let auth = await gbfGenerate.generateToken();
      this.axios
        .get(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/get_key_for_rename_document",
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            for (let i = 0; i < response.data.result.length; i++) {
              let keyrename = {};
              keyrename["key"] = response.data.result[i];
              keyrename["text"] = response.data.result[i];
              keyrename["name"] = "renamedocument." + response.data.result[i];
              this.datakeyrename.push(keyrename);
              if (i + 1 === response.data.result.length) {
                if (this.statuseditrenamedocument === true) {
                  let indexdoctype =
                    item.document_type_code === "default"
                      ? (this.statuscheckdefault = true)
                      : this.listDoctypeuse.findIndex(
                          (element) =>
                            element.document_type_code ===
                            item.document_type_code
                        );
                  item.document_type_code === "default"
                    ? ""
                    : this.documenttype.push(
                        this.listDoctypeuse[indexdoctype].document_type_code
                      );
                  for (let j = 0; j < item.template.length; j++) {
                    let data;
                    let datarename = this.datakeyrename.findIndex(
                      (x) => x.key === item.template[j]
                    );
                    if (datarename !== -1) {
                      data = this.datakeyrename[datarename];
                    } else {
                      data = {
                        key: "textandsymbol",
                        name: "renamedocument.textandsymbol",
                        text: item.template[j],
                        errortext: "",
                      };
                    }
                    this.dataformat.push(data);
                  }
                  this.pagerename = "editTemplatePage";
                }
              }
            }
            this.loadprogress = false;
          } else {
            this.loadprogress = false;
            Toast.fire({
              icon: "error",
              title:
                response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    async setTemplateRenameDocument() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("arrayformattemplate", this.dataformat);
        return;
      }
      let rawdatadocumenttype;
      if (this.statuscheckdefault) {
        rawdatadocumenttype = ["default"];
      } else {
        rawdatadocumenttype = this.documenttype;
      }
      let arrayformattemplate = this.dataformat.map((x) => (x = x.text));
      for (let i = 0; i < rawdatadocumenttype.length; i++) {
        let datadoctype =
          this.statuscheckdefault === true
            ? ""
            : this.listDoctypeuse.filter(
                (item) => item.document_type_code === rawdatadocumenttype[i]
              );
        let payload = {
          account_id: this.dataAccountId,
          business_id: this.dataAccountActive.business_info.business_id,
          document_type: this.statuscheckdefault
            ? rawdatadocumenttype[0]
            : datadoctype[0].document_type_th,
          document_type_code: this.statuscheckdefault
            ? rawdatadocumenttype[0]
            : datadoctype[0].document_type_code,
          template_detail: arrayformattemplate,
          inbox_status: this.statusinboxoutbox,
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/insert_template_rename_document",
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              Toast.fire({
                icon: "success",
                title: this.$t(
                  "renamedocument.selectformatrenamedocumentsuccess"
                ),
              });
              this.getTemplateRenameDocument();
              this.pagerename = "getTemplatePage";
            } else {
              Toast.fire({
                icon: "error",
                title: this.$t("renamedocument.selectformatrenamedocumentfail"),
              });
            }
            this.$v.$reset();
          })
          .catch((error) => {
            console.log("error", error);
            this.$v.$reset();
            Toast.fire({
              icon: "error",
              title: this.$t("renamedocument.selectformatrenamedocumentfail"),
            });
          });
      }
    },
    async editTemplateRenameDocument(item) {
      console.log("item", item);
      this.loadprogress = true;
      this.statuseditrenamedocument = true;
      if (item.document_type_code === "default") {
        await this.fnSelectAllDocType();
      }
      await this.getKeyRenameDocument(item);
    },
  },
  mounted() {
    this.getDataDocType();
  },
};
</script>
